<template>
  <el-tabs tab-position="left" v-model="tabActive" v-loading="this.isLoading">
    <el-tab-pane label="Lbq" name="Lbq">
      <LoopMachineTabData :data="allData.Lbq"/>
    </el-tab-pane>
    <el-tab-pane label="Endless" name="Endless">
      <LoopMachineTabData :data="allData.Endless"/>
    </el-tab-pane>
    <el-tab-pane label="RaidbossGrand" name="RaidbossGrand">
      <LoopMachineTabData :data="allData.RbGrand"/>
    </el-tab-pane>
    <el-tab-pane label="Versus" name="Versus">
      <LoopMachineTabData :data="allData.Versus"/>
    </el-tab-pane>
  </el-tabs>
</template>

<script>

import LoopMachineTabData from "./LoopMachineTabData.vue"
import request from "@/utils/request-service-base";
import moment from "moment/moment";
import {Message} from "element-ui";

function noop() {}

export default {
  name: "loopMachine",
  components: {
    LoopMachineTabData
  },
  props: {

  },
  data: () => {
    return {
      tabActive: "Lbq",
      isLoading: true,
      allData : {},
    }
  },
  async beforeMount() {
    await this.getDataFromServer();
  },
  methods: {
    async getDataFromServer() {
      this.isLoading = true;

      try {
        let data = await request({
          url: `/maintain/leaderboard/personal/loop-machine/get-info`,
          method: `get`
        });

        this.allData = data.data;
      }
      catch (ex) {
        Message({
          message: `Get data from server error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
      }

      this.isLoading = false;
    },
  },
  computed: {
  },
  mounted() {
    this.$root.$on(`loop-machine-change-loading`, (isEnable) => {
      this.isLoading = isEnable;
    });

    this.$root.$on(`loop-machine-refresh-data`, async () => {
      this.isLoading = true;
      await this.getDataFromServer();

      this.isLoading = false;
    })
  },
};

</script>