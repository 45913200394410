<template>
  <div>
    <el-form ref="form" label-width="300px">
      <MyFormItem label="Current Table Index" tooltip="Table Index đang active">
        <el-input class="form-input" v-model="data.TableIndex" size="normal" disabled/>
      </MyFormItem>

      <el-button type="primary" @click="getData()">
        Refresh
      </el-button>

      <br/>
      <br/>

      <el-table :data="showLbMetaData.result" v-if="showLbMetaData.result.length > 0">
        <el-table-column label="">
          <template #default="lbData">
            <el-collapse class="space-to-bottom" :accordion="false" >
              <el-collapse-item :title="`Show TableIndex ${lbData.row.TableIndex}`">
                <MyFormItem label="Table Index" tooltip="">
                  <el-input class="form-input" v-model="lbData.row.TableIndex" size="normal" readonly/>
                </MyFormItem>
                <MyFormItem label="EventId" tooltip="">
                  <el-input class="form-input" v-model="lbData.row.EventId" size="normal" readonly/>
                </MyFormItem>
                <MyFormItem label="Event Name" tooltip="">
                  <el-input class="form-input" v-model="lbData.row.EventName" size="normal" readonly/>
                </MyFormItem>
                <MyFormItem label="Coming Soon" tooltip="">
                  <el-date-picker
                      v-model="lbData.row.Time.ComingSoon"
                      type="datetime"
                      value-format="timestamp"
                      placeholder="Select date and time"
                      readonly>
                  </el-date-picker>
                </MyFormItem>
                <MyFormItem label="Start Time" tooltip="">
                  <el-date-picker
                      v-model="lbData.row.Time.Start"
                      type="datetime"
                      value-format="timestamp"
                      placeholder="Select date and time"
                      readonly>
                  </el-date-picker>
                </MyFormItem>
                <MyFormItem label="Start Time" tooltip="">
                  <el-date-picker
                      v-model="lbData.row.Time.End"
                      type="datetime"
                      value-format="timestamp"
                      placeholder="Select date and time"
                      readonly>
                  </el-date-picker>
                </MyFormItem>
                <MyFormItem label="Limit" tooltip="">
                  <el-input class="form-input" v-model="lbData.row.Time.Limit" size="normal" readonly/>
                </MyFormItem>
                <MyFormItem label="Affect From Version" tooltip="">
                  <el-input class="form-input" v-model="lbData.row.AffectFromVersion" size="normal" readonly/>
                </MyFormItem>
                <MyFormItem label="Affect To Version" tooltip="">
                  <el-input class="form-input" v-model="lbData.row.AffectToVersion" size="normal" readonly/>
                </MyFormItem>
                <MyFormItem label="Current Index Config" tooltip="">
                  <el-input class="form-input" v-model="lbData.row.ConfigIndex" size="normal" readonly/>
                </MyFormItem>

                <UploadTitle>Reward List</UploadTitle>
                <el-collapse class="space-to-bottom" :accordion="false" v-if="lbData.row.RewardList.length > 0">
                  <el-collapse-item title="View Imported Data">
                    <el-table :data="lbData.row.RewardList" border>
                      <el-table-column
                          prop="Name"
                          label="Name"
                          width="150"
                      />
                      <el-table-column
                          label="Reward List"
                      >
                        <template #default="propsGift">
                          <el-table :data="propsGift.row.Gift" border>
                            <el-table-column
                                prop="id"
                                label="Index"
                                width="100"/>
                            <el-table-column
                                prop="loopSet"
                                label="LoopSet"
                                :filters="loopSetFilter"
                                :filter-method="filterHandler"
                            />
                            <el-table-column
                                prop="title"
                                label="Title"/>
                            <el-table-column
                                prop="rankMin"
                                label="RankMin"/>
                            <el-table-column
                                prop="rankMax"
                                label="RankMax"/>
                            <el-table-column
                                prop="requireScore"
                                label="RequireScore"/>
                            <el-table-column
                                prop="rewards"
                                label="Rewards"
                                width="500">
                              <template #default="propsRewards">
                                <el-table :data="propsRewards.row.rewards" border>
                                  <el-table-column
                                      prop="code"
                                      label="Code">
                                  </el-table-column>
                                  <el-table-column
                                      prop="value"
                                      label="Value"
                                      width="100">
                                  </el-table-column>
                                </el-table>
                              </template>
                            </el-table-column>
                          </el-table>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-collapse-item>
                </el-collapse>

                <UploadTitle>Clan Reward List</UploadTitle>
                <el-collapse class="space-to-bottom" :accordion="false" v-if="lbData.row.ClanRewardList !== undefined && lbData.row.ClanRewardList.length > 0">
                  <el-collapse-item title="View Imported Data">
                    <el-table :data="lbData.row.ClanRewardList" border>
                      <el-table-column
                          prop="Name"
                          label="Name"
                          width="150"
                      />
                      <el-table-column
                          label="Reward List"
                      >
                        <template #default="propsClanGift">
                          <el-table :data="propsClanGift.row.Gift" border>
                            <el-table-column
                                prop="id"
                                label="Index"
                                width="100"/>
                            <el-table-column
                                prop="loopSet"
                                label="LoopSet"
                                :filters="loopSetFilter"
                                :filter-method="filterHandler"
                            />
                            <el-table-column
                                prop="title"
                                label="Title"/>
                            <el-table-column
                                prop="rankMin"
                                label="RankMin"/>
                            <el-table-column
                                prop="rankMax"
                                label="RankMax"/>
                            <el-table-column
                                prop="requireScore"
                                label="RequireScore"/>
                            <el-table-column
                                prop="rewards"
                                label="Rewards"
                                width="500">
                            </el-table-column>
                          </el-table>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-collapse-item>
                </el-collapse>

                <UploadTitle>Avatar Replace</UploadTitle>
                <el-collapse class="space-to-bottom" :accordion="false" v-if="lbData.row.AvatarReplace.length > 0">
                  <el-collapse-item title="View Imported Data">
                    <el-table :data="data.AvatarReplace" border>
                      <el-table-column
                          type="index"
                          label="Index"
                          width="200"
                      />

                      <el-table-column
                          label="AvatarId"
                      >
                        <template #default="scope">
                          {{ scope.row }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-collapse-item>
                </el-collapse>
                <MyFormItem label="Avatar Replace gold" tooltip="">
                  <el-input class="form-input" v-model="lbData.row.AvatarReplaceGold" size="normal" readonly/>
                </MyFormItem>

                <UploadTitle>AvatarFrame Replace</UploadTitle>
                <el-collapse class="space-to-bottom" :accordion="false" v-if="lbData.row.AvatarFrameReplace.length > 0">
                  <el-collapse-item title="View Imported Data">
                    <el-table :data="lbData.row.AvatarFrameReplace" border>
                      <el-table-column
                          prop="Index"
                          label="Index"
                          width="200"
                      />
                      <el-table-column
                        v-for="column in lbData.row.AvatarFrameReplaceColumn"
                        :key="column.label"
                        :prop="column.prop"
                        :label="column.label"
                      />
                    </el-table>
                  </el-collapse-item>
                </el-collapse>

                <UploadTitle>Group Config</UploadTitle>
                <el-collapse class="space-to-bottom" :accordion="false" v-if="lbData.row.GroupConfig.length > 0">
                  <el-collapse-item title="View Imported Data">
                    <el-table :data="lbData.row.GroupConfig" border>
                      <el-table-column
                          prop="TypeUser"
                          label="Type User (Cheater, Tester, Normal)">
                      </el-table-column>
                      <el-table-column
                          prop="Country"
                          label="Country (All, Tier1, Tier3, Other)">
                      </el-table-column>
                      <el-table-column
                          prop="Region.Min"
                          label="Region Min">
                      </el-table-column>
                      <el-table-column
                          prop="Region.Max"
                          label="Region Max">
                      </el-table-column>
                      <el-table-column
                          prop="TotalLeaderBoard"
                          label="Total LeaderBoard">
                      </el-table-column>
                      <el-table-column
                          prop="MaxUserInLeaderBoard"
                          label="Max User In LeaderBoard">
                      </el-table-column>
                      <el-table-column
                          prop="MaxPayUser"
                          label="Max PayUser">
                      </el-table-column>
                      <el-table-column
                          prop="MaxFreeUser1"
                          label="Max FreeUser1">
                      </el-table-column>
                      <el-table-column
                          prop="RewardName"
                          label="Reward Name">
                      </el-table-column>
                    </el-table>
                  </el-collapse-item>
                </el-collapse>

                <UploadTitle>Clan Group Config</UploadTitle>
                <el-collapse class="space-to-bottom" :accordion="false" v-if="lbData.row.ClanGroupConfig !== undefined && lbData.row.ClanGroupConfig.length > 0">
                  <el-collapse-item title="View Imported Data">
                    <el-table :data="lbData.row.ClanGroupConfig" border>
                      <el-table-column
                          prop="TypeClan"
                          label="Type Clan (Cheater, Normal)">
                      </el-table-column>
                      <el-table-column
                          prop="Country"
                          label="Country (All, Usa, Tier1, Tier3, Other)">
                      </el-table-column>
                      <el-table-column
                          prop="TotalLeaderBoard"
                          label="Total LeaderBoard">
                      </el-table-column>
                      <el-table-column
                          prop="MaxClanInLeaderBoard"
                          label="Max Clan In LeaderBoard">
                      </el-table-column>
                      <el-table-column
                          prop="RewardName"
                          label="Reward Name">
                      </el-table-column>
                    </el-table>
                  </el-collapse-item>
                </el-collapse>

                <div v-if="data.TypeLb === `RbGrand`">
                  <UploadTitle>Boss Settings</UploadTitle>
                  <MyFormItem label="Count" tooltip="Số lượng boss trong 1 event">
                    <el-input class="form-input" v-model="lbData.row.BossConfig.Count" size="normal" readonly/>
                  </MyFormItem>
                  <MyFormItem label="Active Time" tooltip="Khoảng thời gian giữa 2 con boss - session 1 con boss (giây)">
                    <el-input class="form-input" v-model="lbData.row.BossConfig.ActiveTime" size="normal" readonly/>
                  </MyFormItem>

                  <MyFormItem label="Living Time" tooltip="Thời gian con boss hoạt động trong session trên (giây)">
                    <el-input class="form-input" v-model="lbData.row.BossConfig.LivingTime" size="normal" readonly/>
                  </MyFormItem>
                </div>

                <div v-if="data.TypeLb === `Versus`">
                  <UploadTitle>Versus Config</UploadTitle>

                  <MyFormItem label="Result Log count" tooltip="Result Log count">
                    <el-input class="form-input" v-model="lbData.row.VersusConfig.ResultLogCount" size="normal" readonly/>
                  </MyFormItem>

                  <MyFormItem label="Not same user in battle count" tooltip="Not same user in battle count">
                    <el-input class="form-input" v-model="lbData.row.VersusConfig.NotSameUserInBattleCount" size="normal" readonly/>
                  </MyFormItem>

                  <MyFormItem label="Point range" tooltip="Point range">
                    <el-input class="form-input" v-model="lbData.row.VersusConfig.PointRange" size="normal" readonly/>
                  </MyFormItem>

                  <MyFormItem label="Power delta" tooltip="Power delta">
                    <el-input class="form-input" v-model="lbData.row.VersusConfig.PowerDelta" size="normal" readonly/>
                  </MyFormItem>

                  <MyFormItem label="Battle count" tooltip="Battle Need To Find Real User">
                    <el-input class="form-input" v-model="lbData.row.VersusConfig.BattleNeedToFindUser" size="normal" readonly/>
                  </MyFormItem>

                  <MyFormItem label="Battle Id View" tooltip="Battle ID, download icon appear">
                    <el-input class="form-input" v-model="lbData.row.VersusConfig.BattleIdView" size="normal" readonly/>
                  </MyFormItem>

                  <MyFormItem label="Battle Id Play" tooltip="Battle ID, can play event">
                    <el-input class="form-input" v-model="lbData.row.VersusConfig.BattleIdPlay" size="normal" readonly/>
                  </MyFormItem>
                </div>
              </el-collapse-item>


            </el-collapse>
          </template>
        </el-table-column>
      </el-table>

    </el-form>
  </div>
</template>

<script>

import MyFormItem from "@/views/loop-machine/component/myFormItem.vue"
import request from "@/utils/request-service-base";
import {Message} from "element-ui";
import UploadTitle from "@/views/loop-machine/component/uploadTitle.vue";
//import SpanTime from "@/views/loop-machine/component/spanTime.vue";
//import TimeInput from "@/views/loop-machine/component/timeInput.vue";

export default {
  components:{
    //TimeInput, SpanTime,
    UploadTitle,
    MyFormItem
  },
  props: {
    data: {},
  },
  computed: {
    loopSetFilter() {
      let retData = [];
      for (let i = 0; i < 10; i++) {
        retData.push({
          text: `${i}`, value: i
        });
      }

      return retData;
    },
  },
  data: () => {
    return {
      showLbMetaData: {
        result: []
      },
    }
  },
  methods: {
    generalAvatarFrameReplace(avatarFrameReplaceBaseData) {
      let avatarFrameReplace = [];
      let columnData = [];
      let title = [];
      let maxCount = 0;

      for (let slotIndex = 0; slotIndex < avatarFrameReplaceBaseData.length; slotIndex++) {
        title.push(`Slot_${slotIndex}`);
        maxCount = avatarFrameReplaceBaseData[slotIndex].length > maxCount ? avatarFrameReplaceBaseData[slotIndex].length : maxCount;
      }

      for (let i = 0; i < maxCount; i++) {
        let dataTemp = {
          Index: i + 1,
        };

        for (let j = 0; j < title.length; j++) {
          dataTemp[title[j]] = avatarFrameReplaceBaseData[j][i];
        }

        avatarFrameReplace.push(dataTemp);
      }

      for (let j = 0; j < title.length; j++) {
        columnData.push({
          label: `Slot_${j}`,
          prop: `Slot_${j}`
        });
      }

      return {
        data: avatarFrameReplace,
        column: columnData
      };
    },

    async getData() {
      try {
        this.$root.$emit("event-change-loading", true);
        this.showLbMetaData.result = [];

        let responseFull = await request({
          url: `/maintain/leaderboard/personal/get-last-config-meta-data/${this.data.TypeLb}`,
          method: `get`,
        });
        this.$root.$emit("event-change-loading", false);

        let response = responseFull.data;

        for (let i = 0; i < response.Data.length; i++) {
          let lbData = response.Data[i];
          let time = lbData.Time;
          time.ComingSoon *= 1000;
          time.Start *= 1000;
          time.End *= 1000;

          let avatarFrameReplaceRender = this.generalAvatarFrameReplace(lbData.AvatarFrameReplace);

          this.showLbMetaData.result.push({
            TableIndex: lbData.TableIndex,
            EventId: lbData.EventId,
            AffectFromVersion: lbData.AffectFromVersion,
            AffectToVersion: lbData.AffectToVersion,
            AvatarFrameReplace: avatarFrameReplaceRender.data,
            AvatarFrameReplaceColumn: avatarFrameReplaceRender.column,
            AvatarReplace: lbData.AvatarReplace,
            AvatarReplaceGold: lbData.AvatarReplaceGold,
            ConfigIndex: lbData.ConfigIndex,
            EventName: lbData.EventName,
            GroupConfig: lbData.GroupConfig,
            ClanGroupConfig: lbData.ClanGroupConfig,
            Guid: lbData.Guid,
            RewardList: lbData.RewardList,
            ClanRewardList: lbData.ClanRewardList,
            Time: time,
            BossConfig: lbData.BossConfig,
            VersusConfig: lbData.VersusConfig,
          });

        }
      }
      catch (ex) {
        Message({
          message: `Get leaderboard error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },

    async getLeaderBoard() {
      if (this.showLeaderBoard.groupName === "") {
        return;
      }

      try {
        this.$root.$emit("event-change-loading", true);
        this.showLeaderBoard.result = [];
        let groupName = this.showLeaderBoard.groupName;
        if (this.showLeaderBoard.groupNameCustom !== "") {
          groupName = this.showLeaderBoard.groupNameCustom;
        }
        let responseFull = await request({
          url: `/maintain/leaderboard/personal/get-leaderboard-detail/${this.data.TypeLb}_${this.showLeaderBoard.tableIndex}_${groupName}_${this.showLeaderBoard.lbIndex}`,
          method: `get`,
        });

        let response = responseFull.data;

        this.$root.$emit("event-change-loading", false);

        for (let i = 0; i < response.Lb.length; i++) {
          this.showLeaderBoard.result.push({
            Rank: i + 1,
            UserId: response.Lb[i].UserId,
            Score: response.Lb[i].Score,
          });
        }
      }
      catch (ex) {
        Message({
          message: `Get leaderboard error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.$root.$emit("event-change-loading", false);
      }
    },

    filterHandler(value, row, column) {
      let property = column['property']
      return row[property] === value
    },
  },
}
</script>

<style scoped lang="scss">

</style>