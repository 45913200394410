<template>
  <el-tabs tab-position="left" v-model="tabActive">
    <el-tab-pane label="Lbq" name="Lbq">
      <LastLbMetaDataTabData :data="allData.Lbq"/>
    </el-tab-pane>
    <el-tab-pane label="Endless" name="Endless">
      <LastLbMetaDataTabData :data="allData.Endless"/>
    </el-tab-pane>
    <el-tab-pane label="RaidbossGrand" name="RaidbossGrand">
      <LastLbMetaDataTabData :data="allData.RbGrand"/>
    </el-tab-pane>
<!--    <el-tab-pane label="RaidbossLittle" name="RaidbossLittle">-->
<!--      <LastLbMetaDataTabData :data="allData.RbLittle"/>-->
<!--    </el-tab-pane>-->
    <el-tab-pane label="Versus" name="Versus">
      <LastLbMetaDataTabData :data="allData.Versus"/>
    </el-tab-pane>
  </el-tabs>
</template>

<script>

import LastLbMetaDataTabData from "./LastLbMetaDataTabData.vue"

function noop() {}

export default {
  name: "event",
  components: {
    LastLbMetaDataTabData
  },
  props: {
    allData : {},
  },
  data: () => {
    return {
      tabActive: "Lbq",
    }
  },
  methods: {
  }
};

</script>