<template>
  <div class="app-container" v-loading="loading">
    <el-form ref="form" label-width="300px">
      <UploadTitle>Clan Member Gift</UploadTitle>
      <MyFormItem label="Avatar Replace" tooltip="Đẩy file Clan Member Gift lên">
        <el-row :gutter="20" type="flex" justify="start">
          <el-col :offset="0">
            <el-upload
                drag
                action=""
                :file-list="clanMemberRewardConfigList"
                :auto-upload="false"
                ref=""
                :multiple="false"
                :on-change="handleChangeClanMemberRewardFileList"
                :on-remove="handleChangeClanMemberRewardFileList"
                class="file-list-item">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
            </el-upload>
          </el-col>
          <el-col  :offset="0">
            <el-tooltip content="Tải file .mlb đang show bên dưới" placement="top-start" effect="dark">
              <el-button size="default" @click="handleDownloadCurrentAvatarReplace"><i class="el-icon-download"></i> Download current config</el-button>
            </el-tooltip>
          </el-col>
        </el-row>
      </MyFormItem>

      <el-collapse class="space-to-bottom" :accordion="false" v-if="clanMemberRewardConfig.listData.length > 0">
        <el-collapse-item title="View Imported Data">
          <el-table :data="clanMemberRewardConfig.listData" border>
            <el-table-column
                prop="AndroidProductId"
                label="AndroidProductId"
            />
            <el-table-column
                prop="IosProductId"
                label="IosProductId"
            />
            <el-table-column
                prop="Description"
                label="Description"
            />
            <el-table-column
                prop="ResourceType"
                label="ResourceType"
            />
            <el-table-column
                prop="Quantity"
                label="Quantity"
            />
          </el-table>
        </el-collapse-item>
      </el-collapse>

      <br/>
      <el-button type="primary" @click="onTapUpdateClanMemberGift()">
        Update Clan Member Gift
      </el-button>

      <br/>
      <br/>

      <span>Get Receipt by UserId</span>

      <MyFormItem label="UserId" tooltip="" >
        <el-input class="form-input" v-model="listReceiptByUserId.userId" size="normal"/>
      </MyFormItem>

      <el-button type="primary" @click="onTapGetListReceipt()">
        Get List Receipt
      </el-button>

      <el-pagination
          background layout="prev, pager, next"
          :total="listReceiptByUserId.total"
          :page-size="listReceiptByUserId.limit"
          :current-page="listReceiptByUserId.page"
          @current-change="onListReceiptPageChange"
          v-if="listReceiptByUserId.listData.length > 0"
      />
      <el-table :data="listReceiptByUserId.listData" border v-if="listReceiptByUserId.listData.length > 0">
        <el-table-column
            prop="OrderId"
            label="OrderId"/>
        <el-table-column
            prop="TimeLog"
            label="TimeLog (DD-MM-YYYY HH:mm:ss)"/>
        <el-table-column
            prop="ProductId"
            label="ProductId"/>
<!--        <el-table-column-->
<!--            prop="PurchaseToken"-->
<!--            label="PurchaseToken"/>-->
        <el-table-column
            label="Command"
        >
          <template #default="scope">
            <el-button type="primary" round @click="handleTapGetDetail(scope.row)">
              Get Detail
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <br/>
      <br/>

      <div ref="receipt-info">
        <span>Get Receipt info</span>

        <MyFormItem label="OrderId" tooltip="" >
          <el-input class="form-input" v-model="receiptByOrderId.orderId" size="normal"/>
        </MyFormItem>

        <el-button type="primary" @click="getReceiptInfo()">
          Get Receipt info
        </el-button>

        <el-table :data="receiptByOrderId.result" border v-if="receiptByOrderId.result.length > 0">

          <el-table-column
              prop="OrderId"
              label="OrderId"/>
          <el-table-column
              prop="UserId"
              label="UserId"/>
          <el-table-column
              prop="TimeLog"
              label="TimeLog (DD-MM-YYYY HH:mm:ss)"/>
          <el-table-column
              prop="ProductId"
              label="ProductId"/>
        </el-table>

        <br/>
        <br/>
        <json-viewer :value="receiptByOrderId.payloadJson" v-if="receiptByOrderId.payloadJson !== null"></json-viewer>

      </div>

    </el-form>
  </div>
</template>

<script>

import DownloadFile from '@/helpers/downloadFile'
import GenerateMLBFileContent from '@/helpers/generateMLBFileContent'
import ParseMLBfile from '@/helpers/parseMLBfile'
import ReadFile from '@/helpers/readFile'
import MyFormItem from "@/views/loop-machine/component/myFormItem.vue";
import request from "@/utils/request-service-base";
import {Message} from "element-ui";
import JsonViewer from 'vue-json-viewer'
import moment from "moment/moment";
import UploadTitle from "@/views/loop-machine/component/uploadTitle.vue";

export default {
  components: {
    UploadTitle,
    MyFormItem,
    JsonViewer,
  },
  data: () => {
    return {
      listReceiptByUserId: {
        userId: "",
        pageInput: 1,
        listData: [],
        total: 0,
        page: 0,
        limit: 0,
      },

      receiptByOrderId: {
        orderId: "",
        result: [],
        payloadJson: null,
      },

      clanMemberRewardConfig: {
        listData: [],
      },

      loading: true,
    }
  },
  async beforeMount() {
    await this.getGlobalData();

    this.isLoading = false;
  },
  computed: {
    clanMemberRewardConfigList: {
      get: function() {
        if (this.clanMemberRewardConfig.listData.length === 0) {
          return [];
        }

        return [
          {
            name: "ClanMemberReward",
            rawText: this.generateMLBFileContentOfClanMemberRewardConfig(this.clanMemberRewardConfig.listData)
          }
        ];
      }
    },
  },
  methods: {
    scrollMeTo(refName) {
      let element = this.$refs[refName];
      let top = element.offsetTop;

      window.scrollTo(0, top);
    },

    formatDateSecondToLocal(time) {
      return `${moment.unix(time).local().format("DD-MM-YYYY HH:mm:ss")}`;
    },

    formatDateMilliSecondToLocal(time) {
      return this.formatDateSecondToLocal(time * 0.001);
    },

    async getGlobalData() {
      try {
        this.clanMemberRewardConfig.listData = [];
        this.loading = true;
        let response = await request({
          url: `/maintain/verify/get-clan-gift-member`,
          method: `get`,
        });

        this.loading = false;

        for (let i = 0; i < response.data.Data.length; i++) {
          response.data.Data[i].Index = i;
        }

        this.clanMemberRewardConfig.listData = response.data.Data;
      }
      catch (ex) {
        Message({
          message: `Get global data error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
      }
    },

    async getReceiptByUserId() {
      try {
        this.listReceiptByUserId.listData = [];

        this.loading = true;
        let response = await request({
          url: `/maintain/verify/get-receipt-by-userid/${this.listReceiptByUserId.userId}?page=${this.listReceiptByUserId.pageInput}`,
          method: `get`,
        });

        this.loading = false;

        let dataResponse = response.data;

        this.listReceiptByUserId.listData = dataResponse.data;
        this.listReceiptByUserId.total = dataResponse.paging.total;
        this.listReceiptByUserId.limit = dataResponse.paging.limit;
        this.listReceiptByUserId.page = parseInt(dataResponse.paging.page);

        this.listReceiptByUserId.pageInput = this.listReceiptByUserId.page;

        for (let i = 0; i < this.listReceiptByUserId.listData.length; i++) {
          let data = this.listReceiptByUserId.listData[i];
          data.TimeLog = this.formatDateSecondToLocal(data.TimeLog);
        }
      }
      catch (ex) {
        Message({
          message: `Get receipt error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.loading = false;
      }
    },

    async onTapGetListReceipt() {
      this.listReceiptByUserId.pageInput = 1;
      await this.getReceiptByUserId();
    },

    async onListReceiptPageChange(val) {
      this.listReceiptByUserId.pageInput = val;
      await this.getReceiptByUserId();
    },

    async getReceiptInfo(){
      try {
        this.receiptByOrderId.result = [];
        this.receiptByOrderId.payloadJson = "";
        this.loading = true;
        let responseReceipt = await request({
          url: `/maintain/verify/get-receipt/${this.receiptByOrderId.orderId}`,
          method: `get`,
        });

        this.loading = false;

        let responseData = responseReceipt.data;
        let receiptData = {
          OrderId: responseData.Data.OrderId,
          UserId: responseData.Data.UserId,
          TimeLog: this.formatDateSecondToLocal(responseData.Data.TimeLog),
          ProductId: responseData.Data.ProductId,
          PurchaseToken: responseData.Data.PurchaseToken,
        };

        this.receiptByOrderId.result.push(receiptData);

        if (responseData.Payload.purchaseTimeMillis !== undefined) {
          responseData.Payload.purchaseTimeMillis = this.formatDateMilliSecondToLocal(responseData.Payload.purchaseTimeMillis);
        }

        if (responseData.Payload.expiryTimeMillis !== undefined) {
          responseData.Payload.expiryTimeMillis = this.formatDateMilliSecondToLocal(responseData.Payload.expiryTimeMillis);
        }

        if (responseData.Payload.startTimeMillis !== undefined) {
          responseData.Payload.startTimeMillis = this.formatDateMilliSecondToLocal(responseData.Payload.startTimeMillis);
        }

        this.receiptByOrderId.payloadJson = responseData.Payload;
      }
      catch (ex) {
        Message({
          message: `Get receipt info error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.loading = false;
      }
    },

    async handleTapGetDetail(row) {
      try {
        this.receiptByOrderId.orderId = row.OrderId;
        await this.getReceiptInfo();

        this.scrollMeTo("receipt-info");
      }
      catch (ex) {
        Message({
          message: `Get receipt detail error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.loading = false;
      }
    },

    generateMLBFileContentOfClanMemberRewardConfig(listData) {
      let dataList = [];

      for (let j = 0; j < listData.length; j++) {
        let data = listData[j];
        let tempString = `${data.AndroidProductId}\t${data.IosProductId}\t${data.Description}\t${data.ResourceType}\t${data.Quantity}`;
        dataList.push(tempString);
      }

      return GenerateMLBFileContent(`ClanMemberRewardConfig`,
          ['AndroidProductId', 'IosProductId', 'Description', 'ResourceType', 'Quantity'],
          ['string', 'string', 'string', 'string', 'int'],
          dataList);
    },

    async handleChangeClanMemberRewardFileList(file, fileList) {
      this.clanMemberRewardConfig.listData = [];

      if (fileList.length !== 1) {
        return;
      }

      this.loading = true;

      if (fileList[0].raw) {
        fileList[0].rawText = await ReadFile(fileList[0].raw);
      }

      let jsonArray = ParseMLBfile(fileList[0].rawText, true);
      for (let j = 0; j < jsonArray.length; j++) {
        let data = jsonArray[j];
        this.clanMemberRewardConfig.listData.push({
          AndroidProductId: data.AndroidProductId,
          IosProductId: data.IosProductId,
          Description: data.Description,
          ResourceType: data.ResourceType,
          Quantity: data.Quantity,
        });
      }

      this.loading = false;
    },

    handleDownloadCurrentAvatarReplace() {
      DownloadFile(`ClanMemberRewardConfig.mlb`,
          this.generateMLBFileContentOfClanMemberRewardConfig(this.clanMemberRewardConfig.listData));
    },

    async onTapUpdateClanMemberGift() {
      try {
        this.loading = true;
        let response = await request({
          url: `/maintain/verify/update-clan-gift-member`,
          method: `post`,
          data: this.clanMemberRewardConfig.listData,
        });

        await this.getGlobalData();

        this.loading = false;
      }
      catch (ex) {
        Message({
          message: `Update clan gift error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.loading = false;
      }

    },
  }
}
</script>