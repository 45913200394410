<template>
  <div v-loading="this.isLoading">
    <el-tabs type="border-card" tab-position="top" v-model="tabActive">
      <!-- Schedule -->
      <el-tab-pane label="Schedule" name="Schedule">
        <FullCalendar :events="eventScheduleData"/>
      </el-tab-pane>

      <!-- Detail -->
      <el-tab-pane label="Detail" name="Detail">
        <DetailTab :all-data="allEventData" v-if="Object.keys(this.allEventData).length > 0"/>
      </el-tab-pane>

      <!-- LoopMachine -->
      <el-tab-pane label="LoopMachine" name="LoopMachine">
        <LoopMachineTab :all-data="allLoopMachineData" v-if="Object.keys(this.allLoopMachineData).length > 0"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>

import moment from 'moment'
import FullCalendar from "../../../components/FullCalendar"
import request from "@/utils/request-service-base";
import {Message} from "element-ui";
import DetailTab from "./DetailTab.vue";
import LoopMachineTab from "./LoopMachineTab.vue";

export default {
  components: {
    DetailTab,
    FullCalendar,
    LoopMachineTab,
  },
  data: () => {
    return {
      isLoading: true,
      tabActive: "Schedule",

      allEventData: {},
      eventScheduleData: [],
      allLoopMachineData: {},
    }
  },

  async beforeMount() {
    await this.getDataFromServer();

    this.isLoading = false;
  },
  methods: {
    async getDataFromServer() {
      let errorMsg = await this.getDataEventFromServer();
      if (errorMsg !== null) {
        Message({
          message: `${errorMsg}`,
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      errorMsg = await this.getDataLoopMachineFromServer();
      if (errorMsg !== null) {
        Message({
          message: `${errorMsg}`,
          type: "error",
          duration: 5 * 1000,
        });
      }
    },
    async getDataEventFromServer() {
      try {
        let data = await request({
          url: `/maintain/event/get-event-data`,
          method: `get`
        });

        this.allEventData = data.data;
        this.eventScheduleData = [];

        for (let eventType in this.allEventData) {
          let eventData = this.allEventData[eventType];

          let stringDateStart = `${moment.unix(eventData.Start).local().format("DD-MM-YYYY HH:mm")}`;
          let stringDateEnd = `${moment.unix(eventData.End).local().format("DD-MM-YYYY HH:mm")}`;

          let pushData = {
            title: `${eventType} : ${stringDateStart} -> ${stringDateEnd}`,
            start: moment.unix(eventData.Start),
            end: moment.unix(eventData.End),
            textColor: "#000000",
          };

          switch (eventType) {
            case "Wheel":
              pushData.color = "#C2FE67";
              break;
            case "Recharge":
              pushData.color = "#FE67AD";
              break;
            case "LuckyWheel":
              pushData.color = "#67D6FE";
              break;
            case "NonStopOffer":
              pushData.color = "#FEF667";
              break;
          }

          this.eventScheduleData.push(pushData);
        }

        return null;
      } catch (ex) {
        return `Get data event from server error: ${ex}`;
      }
    },
    async getDataLoopMachineFromServer() {
      try {
        let data = await request({
          url: `/maintain/event/loop-machine/get-info`,
          method: `get`
        });

        this.allLoopMachineData = data.data;

        return null;
      } catch (ex) {
        return `Get data loop machine from server error: ${ex}`;
      }
    }
  },

  mounted() {
    this.$root.$on(`event-change-loading`, (isEnable) => {
      this.isLoading = isEnable;
    });

    this.$root.$on(`event-refresh-data`, async () => {
      this.isLoading = true;
      await this.getDataFromServer();

      this.isLoading = false;
    })
  },
}
</script>

<style>

</style>