import { render, staticRenderFns } from "./LastLbMetaDataTabData.vue?vue&type=template&id=612b144a&scoped=true&"
import script from "./LastLbMetaDataTabData.vue?vue&type=script&lang=js&"
export * from "./LastLbMetaDataTabData.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "612b144a",
  null
  
)

export default component.exports